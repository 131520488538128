<template>
    <div class="min-h-[100vh] bg-violet z-[1] md:pt-[200px] pt-[180px] md:pb-[30px] pb-[30px]">
        <div ref="congrats" class="js-container"></div>

        <div class="max-w-[1148px] mx-auto text-center text-white relative z-[1] px-[20px]">
            <h1 class="md:text-[50px] text-[30px] md:mb-[30px] mb-[20px] font-bold">You’re <span v-html="result.stage"></span> {{ result.result }}!</h1>
            <h3 class="md:text-[30px] text-[20px] md:mb-[30px] mb-[20px]" v-html="result.mean"></h3>

            <p class="md:text-[20px] text-[18px] md:mb-[50px] mb-[30px]">Want to know more about your Superpower? Watch this &#x1F447;</p>

            <figure class="relative pt-[56.31%] md:mb-[50px] mb-[30px]">
                <iframe :src="`https://player.vimeo.com/video/${result.videoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="`SANOFI - Calling all Influencers - ${result.stage}`"></iframe>
            </figure>

            <div class="max-w-[623px] mx-auto mb-[50px]">
                <p class="text-[20px]" v-html="result.text"></p>
            </div>
        </div>
    </div>
  <div v-if="snofy === 'no'" class="flex justify-center bg-purple pb-[30px] flex-wrap">
    <div class="custom-text mb-[20px]">
      Discover Sanofi Careers
    </div>
    <div class="custom-text mb-[80px]">
     <img class="max-w-[160px] w-full h-full object-cover centered-block" :src="getImageSrc()" />
    </div>
    <Button @click="$store.commit('reset')" color="white" class="min-w-[246px]">
      <font-awesome-icon icon="fa-regular fa-rotate-right" />
      Start over
    </Button>
  </div>
  <div v-else-if="snofy === 'yes'" class="flex justify-center px-[20px] bg-violet container-questions">
    <iframe width="100%" height="1200px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=1sijrHGqGk6hDgNXL8WMC9gNMWiCXRtDl59jFP47Cn9UNllHTjgyMzEwMkxXTDNIWVZZWU42UEU4SC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style="border: none; max-width:100%; " allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
  </div>
  <div v-if="snofy === 'yes'" class="bg-violet main-posts container-questions">
    <div ref="postContainer" class="post-container"></div>
  </div>

</template>

<script>
import gsap from 'gsap';
import Button from '@/components/Button.vue';

    export default {
        name: 'ResiltsTemplate',
        components: {
            Button
        },
      props: {
        snofy: {
          type: String,
          default: 'yes'
        }
      },
        computed: {
            result() {
                return this.$store.getters.result;
            }
        },
        mounted() {
            gsap.set(this.$refs.congrats, {
                autoAlpha: 1
            })

            this.confettiful();

            gsap.to(this.$refs.congrats, 5, {
                delay: 1,
                autoAlpha: 0,
            })

          const postContainer = this.$refs.postContainer;
          if (postContainer) {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://cdn.walls.io/assets/js/wallsio-widget-1.2.js';
            script.setAttribute('data-wallurl', 'https://my.walls.io/sanofistories?nobackground=1&show_header=0&show_post_info=1&accessibility=0');
            script.setAttribute('data-title', 'Sanofi Stories');
            script.setAttribute('data-width', '100%');
            script.setAttribute('data-autoheight', '1');
            script.setAttribute('data-height', '800');
            script.setAttribute('data-lazyload', '1');

            // Append script to the div with ref 'postContainer'
            postContainer.appendChild(script);
          }


        },
        methods: {
          getImageSrc() {
            return require(`@/assets/img/qr.svg`);
          },
            confettiful() {
                const Confettiful = function(el) {
                    this.el = el;
                    this.containerEl = null;
                    
                    this.confettiFrequency = 3;
                    this.confettiColors = ['#fce18a', '#ff726d', '#b48def', '#f4306d'];
                    this.confettiAnimations = ['slow', 'medium', 'fast'];
                    
                    this._setupElements();
                    this._renderConfetti();
                };

                Confettiful.prototype._setupElements = function() {
                    const containerEl = document.createElement('div');
                    const elPosition = this.el.style.position;
                    
                    if (elPosition !== 'relative' || elPosition !== 'absolute') {
                        this.el.style.position = 'relative';
                    }
                    
                    containerEl.classList.add('confetti-container');
                    
                    this.el.appendChild(containerEl);
                    
                    this.containerEl = containerEl;
                };

            Confettiful.prototype._renderConfetti = function() {
                this.confettiInterval = setInterval(() => {
                    const confettiEl = document.createElement('div');
                    const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
                    const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
                    const confettiLeft = (Math.floor(Math.random() * this.el.offsetWidth)) + 'px';
                    const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];
                    
                    confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
                    confettiEl.style.left = confettiLeft;
                    confettiEl.style.width = confettiSize;
                    confettiEl.style.height = confettiSize;
                    confettiEl.style.backgroundColor = confettiBackground;
                    
                    confettiEl.removeTimeout = setTimeout(function() {
                    confettiEl.parentNode.removeChild(confettiEl);
                    }, 3000);
                    
                    this.containerEl.appendChild(confettiEl);
                }, 25);
            };

            window.confettiful = new Confettiful(this.$refs.congrats);
            }
        }
    }
</script>

<style>
@keyframes confetti-slow {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg); }
}

@keyframes confetti-medium {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg); }
}

@keyframes confetti-fast {
  0% { transform: translate3d(0, 0, 0) rotateX(0) rotateY(0); }
  
  100% { transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg); }
}

.post-container{
  background-color:#fff;
}

.main-posts{
  padding: 0px 1.5vw 10px;
}

.js-container {
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
}

.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}

.confetti--animation-slow {
    animation: confetti-slow 2.25s linear 1 forwards;
}
  
.confetti--animation-medium {
    animation: confetti-medium 1.75s linear 1 forwards;
}
  
.confetti--animation-fast {
    animation: confetti-fast 1.25s linear 1 forwards;
}

.bg-purple{
  background: #7A00E6 0% 0% no-repeat padding-box;
  padding: 77px 10px;
}

.custom-text{
  width:100%;
  text-align:center;
  color:#fff;
  font: italic normal bold 30px/40px Sanofi Serif;
}
.centered-block{
  margin: 0 auto;
}
.container-questions{
top:-2px;
position:relative;
}
</style>