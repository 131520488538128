<template>
  <div class="language-switcher relative w-full block text-center md:absolute md:top-[35px] md:w-auto md:inline-block md:text-left">
    <font-awesome-icon icon="fa-regular fa-globe"/>
    <button :class="{'white chosen': currentLanguage === 'en', 'white': currentLanguage !== 'en'}" @click="switchLanguage('en')">English</button>
    <button :class="{'white chosen': currentLanguage === 'fr', 'white': currentLanguage !== 'fr'}" @click="switchLanguage('fr')">French</button>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';

library.add(faGlobe);

export default {
  name: 'LanguageSwitcher',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      currentLanguage: 'en' // Default to English
    };
  },
  computed: {
    isFrenchSite() {
      return window.location.href.includes('fr.sanofiinfluencerquiz.com');
    },
    isEnglishSite() {
      return window.location.href.includes('sanofiinfluencerquiz.com');
    }
  },
  methods: {
    switchLanguage(language) {
      const urls = {
        en: 'https://sanofiinfluencerquiz.com',
        fr: 'https://fr.sanofiinfluencerquiz.com',
      };
      window.location.href = urls[language];
    }
  },
  mounted() {
    // Check the current URL to determine the language
    if (this.isFrenchSite) {
      this.currentLanguage = 'fr';
    } else if (this.isEnglishSite) {
      this.currentLanguage = 'en';
    }
  }
}
</script>

<style scoped>
.language-switcher {
  top:40px;
  color:#fff;
}

.language-switcher button {
  margin-left: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.chosen:after{
  content: "";
  width:100%;
  height:1px;
  background-color: #fff;
  display:block;
}

@media (max-width: 767px) {
  .language-switcher button {
    margin-left:0px;
    padding-right:0px;
  }
}
</style>
