<template>
    <div :class="selected ? 'border-violet-500' : ''" class="relative rounded-[3px] overflow-hidden flex flex-col border-[1px] border-[#E4E4E4] h-[269px] cursor-pointer transition lg:hover:border-violet-700">
        <figure class="w-full grow relative overflow-hidden bg-[#F4F2F5]">
            <img class="absolute top-[0] left-[0] w-full h-full object-cover" :src="getImageSrc()" />
        </figure>
        <div class="p-[15px] flex gap-[10px] shrink-[0] border-t-[1px] border-[#E4E4E4]">
            <label class="uppercase rounded-[3px] bg-violet-500 h-[20px] w-[20px] text-white flex items-center justify-center shrink-[0] font-sans text-[14px]">
                {{ leater }}
            </label>
            <p ref="text" class="transition-all">
                {{ answer.text }}
            </p>
        </div>
        
        <font-awesome-icon v-if="selected" class="text-violet-500 absolute top-[15px] right-[18px] z-[1]" icon="fa-regular fa-check" />
    </div>
</template>

<script>
    export default {
        name: 'AnswerCard',
        props: {
            selected: Boolean,
            leater: String,
            answer: Object
        },
        methods: {
            getImageSrc() {
                if (!this.answer.imageSrc.trim().length) return '';

                return require(`@/assets/img/questions/${this.answer.imageSrc}`);
            }
        }
    }
</script>