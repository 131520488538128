<template>
    <header  :class="headerClass" ref="header" class=" top-[0] left-[0] w-full  px-[20px] z-[2] flex justify-end">
      <div v-if="$store.state.resultView" class="absolute top-[30px] top-[100px]  left-[20px] md:left-[45px] z-1">
        <Button @click="$store.commit('reset')" color="transperent" class="min-w-[130px] top-button">
          <font-awesome-icon icon="fa-regular fa-rotate-right" />
          Start over
        </Button>
      </div>

        <div :class="iconClass" class="absolute top-[45px] z-10">
            <div class="cursor-pointer" ref="logo" @click="$store.commit('reset')">
                <svg xmlns="http://www.w3.org/2000/svg" width="95.939" height="24.646" viewBox="0 0 95.939 24.646">
                    <g id="Group_11332" data-name="Group 11332" transform="translate(0 0)">
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_1" data-name="Path 1" d="M232.418,148.224a18.667,18.667,0,0,0-6.838-1.284c-5.241,0-8.92,3.506-8.92,9.025,0,5.588,3.194,9.024,8.851,9.024a17.3,17.3,0,0,0,6.907-1.388,1.331,1.331,0,0,0,.868-1.388v-12.6A1.28,1.28,0,0,0,232.418,148.224Zm-3.646,11.351c0,.521-.278.763-.868.937a8.246,8.246,0,0,1-2.36.312c-2.222,0-4.3-1.319-4.3-4.86s2.082-4.86,4.3-4.86a8.307,8.307,0,0,1,2.36.312c.59.174.868.416.868.937Z" transform="translate(-198.35 -140.345)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_2" data-name="Path 2" d="M114.092,152.055c0-1.063,1.076-1.735,2.916-1.735a10.86,10.86,0,0,1,5.241,1.562.975.975,0,0,0,.521.138.9.9,0,0,0,.763-.485l1.076-2.291a1.4,1.4,0,0,0,.138-.59.8.8,0,0,0-.416-.694,14.47,14.47,0,0,0-7.185-1.84c-4.651,0-7.636,2.5-7.636,6.248,0,7.358,11.177,4.51,11.177,9.614a3.807,3.807,0,0,1-.209,1.284.734.734,0,0,0-.035.209.465.465,0,0,0,.521.485h3.123c.521,0,.729-.174.9-.694a6.465,6.465,0,0,0,.278-1.978C125.268,153.408,114.092,155.3,114.092,152.055Z" transform="translate(-109.251 -139.663)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_3" data-name="Path 3" d="M348.334,146.94a20.9,20.9,0,0,0-6.976,1.25,1.327,1.327,0,0,0-.868,1.388v14.37a.656.656,0,0,0,.694.694h3.123a.656.656,0,0,0,.694-.694V152.285c0-.521.278-.763.834-.9a9.856,9.856,0,0,1,2.569-.278c1.875,0,3.368.868,3.368,2.95v9.892a.656.656,0,0,0,.694.694h3.123a.656.656,0,0,0,.694-.694V153.465C356.282,149.231,353.679,146.94,348.334,146.94Z" transform="translate(-301.319 -140.345)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_4" data-name="Path 4" d="M462.618,146.94c-5.207,0-8.608,3.784-8.608,9.025s3.4,9.024,8.851,9.024c5.207,0,8.608-3.784,8.608-9.024S468.068,146.94,462.618,146.94Zm.244,13.883c-2.569,0-4.269-1.666-4.269-4.86s1.7-4.86,4.026-4.86c2.569,0,4.269,1.666,4.269,4.86S465.187,160.823,462.863,160.823Z" transform="translate(-395.715 -140.345)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_5" data-name="Path 5" d="M653.577,149h-3.123a.656.656,0,0,0-.694.694v15.967a.656.656,0,0,0,.694.694h3.123a.656.656,0,0,0,.694-.694V149.694A.656.656,0,0,0,653.577,149Z" transform="translate(-558.489 -142.058)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-black' : ''" id="Path_6" data-name="Path 6" d="M586.943,108.033a9.124,9.124,0,0,0-2.187-.243c-4.3,0-7.116,2.291-7.116,7.289v16.314a.656.656,0,0,0,.694.694h3.09a.656.656,0,0,0,.694-.694V118.55h4.409a.638.638,0,0,0,.694-.694v-2.4a.649.649,0,0,0-.694-.729h-4.409v-.382c0-1.909.832-2.881,2.743-2.881.694,0,1.442.174,1.7.174.416,0,.59-.243.659-.659l.347-1.8a2.452,2.452,0,0,0,.069-.451C587.636,108.341,587.428,108.137,586.943,108.033Z" transform="translate(-498.519 -107.79)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-violet-500' : ''" id="Path_7" data-name="Path 7" d="M110.33,225.65a2.215,2.215,0,0,0-2.36,2.4,2.261,2.261,0,0,0,2.465,2.4,2.4,2.4,0,1,0-.1-4.791Z" transform="translate(-107.97 -205.795)" fill="#fff"/>
                        <path class="transition-colors duration-[500ms] delay-[600ms]" :class="$store.state.quizView && !$store.state.resultView ? 'fill-violet-500' : ''" id="Path_8" data-name="Path 8" d="M651.19,107.83a2.215,2.215,0,0,0-2.36,2.4,2.261,2.261,0,0,0,2.465,2.4,2.4,2.4,0,1,0-.1-4.791Z" transform="translate(-557.716 -107.823)" fill="#fff"/>
                    </g>
                </svg>
            </div>
        </div>
      <LanguageSwitcher v-if="!$store.state.quizView"  />
        <nav v-if="$store.state.quizView" class="transition-opacity duration-[.5s] delay-[1s]" :class="$store.state.quizView && !$store.state.resultView ? 'opacity-[1]' : 'opacity-[0]'">
            <div class="flex">
                <button @click="prev()" :disabled="$store.state.progress < 1" :class="$store.state.progress === 1 ? 'pointer-events-none' : ''" class="h-[50px] w-[50px] flex items-center justify-center text-[20px] text-violet-100 border-[1px] border-violet-100 rounded-l-[3px] relative transition-colors hover:border-violet-700 hover:z-[1] hover:text-violet-700 disabled:pointer-events-none">
                    <font-awesome-icon :class="$store.state.progress > 1 ? 'text-violet-500' : ''" icon="fa-regular fa-arrow-left" />
                </button>
                <button @click="next()" :disabled="$store.state.progress >= ($store.state.answers.length) || $store.state.progress + 1 > $store.state.total" class="h-[50px] w-[50px] flex items-center justify-center text-[20px] text-violet-100 border-[1px] border-violet-100 rounded-r-[3px] ml-[-1px] relative transition-colors hover:border-violet-700 hover:z-[1] hover:text-violet-700 disabled:pointer-events-none">
                    <font-awesome-icon :class="$store.state.progress < ($store.state.answers.length) ? 'text-violet-500' : ''" icon="fa-regular fa-arrow-right" />
                </button>
            </div>
        </nav>
    </header>
</template>

<script>
import { gsap } from 'gsap';
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import Button from "@/components/Button.vue";

    export default {
        name: 'HeaderSection',
      components: {Button, LanguageSwitcher},
      computed: {
        headerClass() {
          return this.$store.state.resultView ? 'fixed bg-violet pt-[45px] pb-[15px]' : 'absolute py-[45px]';
        },
        iconClass(){
          return this.$store.state.resultView ? 'md:left-[50%] md:translate-x-[-50%] md:right-[auto] sm:left-[auto] sm:translate-x-[0%] sm:right-[20px]' : 'left-[50%] translate-x-[-50%]';
        }
      },
        watch: {
            '$store.state.quizView': function () {

                const headerRect = this.$refs.header.getBoundingClientRect();
                const logoRect = this.$refs.logo.getBoundingClientRect();

                const move = this.$store.state.quizView ? (logoRect.x * -1) + 20 : (headerRect.x / 2) - 20 - (logoRect.x / 2)

                gsap.to(this.$refs.logo, .3, {
                    x: move,
                });
            },
            '$store.state.resultView': function () {

                gsap.to(this.$refs.logo, .3, {
                    x: 'center',
                });
            }
        },
        methods: {
            prev() {
                this.$store.commit('prev');
                this.$store.commit('current');
            },

            next() {
                this.$store.commit('next');
                this.$store.commit('current');
            },
        }
    }
</script>
