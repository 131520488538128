<template>
    <ProgressBar />

    <div class="2xl:pt-[180px] xl:pt-[100px] md:pt-[180px] pt-[100px] pb-[70px] min-h-[100vh]">
        <div class="max-w-[958px] mx-auto px-[20px]">
            <label class="text-[20px] text-violet-500 italic font-bold font-serif mb-[20px] block">Question {{ $store.state.progress }}/{{ $store.state.total }}</label>

            <Question />

        </div>
    </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar.vue';
import Question from '@/components/Question.vue';

    export default {
        name: 'QuizTemplate',
        components: {
            ProgressBar,
            Question
        },
        created() {
            this.$store.commit('current');
        }
    }
</script>