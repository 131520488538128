<template>
    <div ref="bar" class="h-[4px] bg-violet-500 fixed top-[0] left-[0]"></div>
</template>

<script>
import gsap from 'gsap';

    export default {
        name: 'ProgressBar',
        watch: {
            '$store.state.progress': function () {
                this.width();
            }
        },
        methods: {
            width() {
                gsap.to(this.$refs.bar, .3, {
                    width: (window.innerWidth / this.$store.state.total) * this.$store.state.progress
                });
            }
        },
        mounted() {
            this.width();

            window.addEventListener('resize', this.width);
        }
    }
</script>