<template>
    <footer class="text-center bg-white md:p-[20px] p-[10px] text-[12px]  left-[0] bottom-[0] w-full z-[1]">
        <ul class="flex md:flex-row flex-col justify-center md:gap-[20px] gap-[5px]">
            <li>© Sanofi 2024 - All rights reserved</li>
            <li>
                <a href="https://www.sanofi.com/en/privacy-and-data-protection" target="_blank">
                    Privacy & data protection
                </a>
            </li>
            <li>
                <a href="https://www.sanofi.com/en/data-privacy" target="_blank">
                    Cookie Policy
                </a>
            </li>
        </ul>
    </footer>
</template>

<script>
    export default {
        name: 'FooterSection'
    }
</script>