<template>
	
	<div class="relative">
		<Header />

		<Transition name="slideup">
			<Hello v-if="!$store.state.quizView && !$store.state.resultView" style="transition-delay: .3s" />
		</Transition>

		<Transition name="slideup">
			<Quiz v-if="$store.state.quizView && !$store.state.resultView" />
		</Transition>

		<Transition name="slideup">
			<Results :snofy="$store.state.youAre" v-if="$store.state.resultView" />
		</Transition>
		
		<Footer />
	</div>

</template>

<script>
import Header from './layout/Header.vue';
import Footer from './layout/Footer.vue';
import Hello from './layout/Hello.vue';
import Quiz from './layout/Quiz.vue';
import Results from './layout/Results.vue';

export default {
	name: 'App',
	components: {
		Header,
		Footer,
		Hello,
		Quiz,
		Results
	}
}
</script>

<style>

.slideup-enter-active,
.slideup-leave-active {
  transition: translate .5s cubic-bezier(.65,-0.32,1,.87);
}

.slideup-enter-from,
.slideup-leave-to {
  translate: 0 -100%;
}

</style>