<template>
    <button ref="button" :class="colors[color]" class="relative px-[30px] h-[50px] transition-all rounded-[3px] flex items-center justify-center gap-x-[10px]">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'ButtonComponent',
        props: {
            color: {
                Type: String,
                Default: 'violet',
                require: false
            }
        },
        data() {
            return {
                colors: {
                    white: 'bg-white border-[1px] border-violet-100 hover:border-violet-700 active:border-[4px] active:border-violet-200',
                    violet: 'bg-violet-500 text-white hover:bg-violet-700 border-[4px] border-[transparent] active:border-violet-200',
                  transperent: 'text-white border-[1px] border-[1px solid #fff] ',
                },
            }
        }
    }
</script>