import { createApp } from 'vue';
import { createStore } from 'vuex';
import './assets/css/tailwind.css';

import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight, faCheck, faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import questionsStage1 from '@/assets/questions/stage_1.json';
import questionsStage2Expected from '@/assets/questions/stage_2_expected.json';
import questionsStage2Unxpected from '@/assets/questions/stage_2_unexpected.json';
import questionsStage3 from '@/assets/questions/stage_3.json';
import results from '@/assets/questions/results.json';

library.add(faArrowRight, faArrowLeft, faCheck, faRotateRight);

function mode(array) {
	if (array.length == 0) return false;

	var modeMap = {},
		maxEl = array[0],
		maxCount = 1;

	for (var i = 0; i < array.length; i++) {
		var el = array[i];

		if (modeMap[el] == null) modeMap[el] = 1;
		else modeMap[el]++;

		if (modeMap[el] > maxCount) {
			maxEl = el;
			maxCount = modeMap[el];
		} else if (modeMap[el] == maxCount) {
			maxEl += '&' + el;
			maxCount = modeMap[el];
		}
	}
	return maxEl;
}

const store = createStore({
	state() {
		return {
			quizView: false,
			resultView: false,
			questions: {
				1: questionsStage1,
				2: {
					expected: questionsStage2Expected,
					unexpected: questionsStage2Unxpected,
				},
				3: questionsStage3,
			},
			youAre:'yes',
			answers: [],
			progress: 1,
			total: 9,
			current: null,
            direction: true, // true up, false down
			results: results
		};
	},

	mutations: {
		showQuiz(state, options) {
			state.youAre = options.selectedOption;
			state.quizView = true;
		},

		apply(state, answer) {

			if (typeof state.answers[state.progress - 1] != 'undefined') {
				state.answers[state.progress - 1] = answer;

				return;
			}

			state.answers.push(answer);
		},

		current(state) {
            
			if (state.progress <= 3) {
				state.current = state.questions[1][state.progress];

                return;
			}

			if (state.progress > 3 && state.progress <= 6) {
                
                if (mode(state.answers) === 'a') {
                    state.current = state.questions[2].expected[state.progress];
                } else {
                    state.current = state.questions[2].unexpected[state.progress];
                }

                return;
            }

			if (state.progress > 6) {
				state.current = state.questions[3][state.progress];

                return;
			}
		},

		next(state) {
            state.direction = true;

            if (state.answers.length === state.total) {

                state.resultView = true;

                return;
            }

			state.progress++;
		},

		prev(state) {
            state.direction = false;
			state.progress--;
		},

		reset(state) {
			state.quizView = false;
			state.resultView = false;

			state.progress = 1;
			state.answers = [];
		}
	},
	getters: {
		result(state) {

			if (state.answers.length < 9) return false;

			const stage1 = mode(state.answers.slice(0, 3));
			const stage2 = mode(state.answers.slice(3, 6));
			const stage3 = mode(state.answers.slice(6, 9));

			return state.results[stage1][stage2][stage3];
		}
	}
});

createApp(App).use(store).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
